<template>
  <div class="tw-relative tw--mt-3" v-if="dataLoaded">
    <div
      class="floating-overlay survey tw-px-10 tw-py-9"
      v-if="showQuestionsOverlay"
    >
      <h2
        class="tw-text-left tw-font-figtree tw-text-lg tw-font-semibold tw-leading-30 tw-text-dark-green tw-mb-8"
      >
        Select Question(s)
      </h2>
      <v-row>
        <v-col>
          <v-btn
            rounded
            block
            large
            class="tw-rounded-full tw-bg-lighter-grey tw-text-dark-green tw-pl-8 tw-shadow-md tw-tracking-normal"
            @click="closeQuestionsOverlay('custom')"
          >
            <v-icon left dark>mdi-plus</v-icon>
            <v-spacer />
            Add Custom question
            <v-spacer />
          </v-btn>
          <h3
            class="tw-text-left tw-text-dark-green tw-text-base tw-mt-8 tw-mb-4 tw-font-semibold"
          >
            Popular questions
          </h3>
          <v-btn-toggle
            class="j-btn-toggle tw-flex-col tw-bg-transparent tw-w-full"
            v-model="surveyQuestionsSelectedTexts"
            borderless
            rounded
            multiple
          >
            <v-btn
              v-for="question in questions"
              :key="question.id"
              :value="question.text"
              rounded
              block
              large
              class="tw-rounded-full tw-bg-lighter-grey tw-text-dark-green tw-mb-5 tw-pl-8 tw-shadow-md tw-tracking-normal"
            >
              <v-icon v-if="!questionInArray(question)" left dark
                >mdi-plus</v-icon
              >
              <v-icon v-if="questionInArray(question)" left dark
                >mdi-check</v-icon
              >
              <v-spacer />
              {{ question.text }}
              <v-spacer />
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <div
        class="tw-absolute tw-flex tw-flex-col tw-items-end tw-top-8 tw-right-10"
      >
        <button
          class="tw-bg-white tw-shadow-custom tw-rounded-full tw-w-10 tw-h-10"
          type="button"
          @click="closeQuestionsOverlay()"
        >
          <v-icon color="#203848">mdi-close</v-icon>
        </button>
      </div>
    </div>
    <v-row class="tw-px-7">
      <v-col>
        <h2
          class="tw-text-left tw-font-figtree tw-text-lg tw-font-semibold tw-leading-30 tw-text-dark-green"
        >
          Edit Survey
        </h2>
        <p
          class="info-blurb tw-text-left tw-text-sm tw-font-figtree tw-font-normal tw-leading-22 tw-text-light-grey tw-py-4"
        >
          Preview and edit the questions and answers.
        </p>
      </v-col>
    </v-row>
    <div class="tw-bg-separator-grey tw-w-full tw-h-2 tw-mb-10"></div>
    <Draggable v-model="surveyQuestionsSelected" handle=".drag-handle">
      <transition-group
        v-for="(question, i) in surveyQuestionsSelected"
        :key="i"
      >
        <div :key="i" class="tw-px-7 tw-mb-10">
          <div
            class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-mx-2"
            :class="!question.addOptionAllowed ? 'tw-mb-5' : 'tw-mb-0'"
          >
            <img
              src="@/assets/svgs/drag.svg"
              alt="Reorder Question"
              class="tw-cursor-pointer tw--ml-4 tw-mr-3 drag-handle"
            />
            <div class="tw-flex tw-items-center tw-justify-between tw-w-full">
              <input
                type="text"
                class="tw-text-left tw-text-dark-green tw-italic tw-text-base tw-font-semibold focus:tw-outline-none tw-w-full"
                v-model="question.text"
                @click="question.text = ''"
                v-if="question.customQuestion"
              />
              <h3
                class="tw-text-left tw-text-dark-green tw-text-base tw-font-semibold"
                v-else
              >
                {{ question.text }}
              </h3>
              <img
                src="@/assets/svgs/surveys/delete-question.svg"
                alt="Delete Question"
                class="tw-cursor-pointer tw-w-7"
                @click.stop="deleteQuestion(question, i)"
              />
            </div>
          </div>
          <div v-if="!question.addOptionAllowed" class="tw-mb-5 tw-mx-2">
            <span
              class="tw-block tw-text-left tw-text-light-grey tw-text-sm tw-ml-2 tw--mt-4 tw-mb-6"
              >Your crew will select from the options below.</span
            >
            <div
              class="tw-bg-lighter-grey tw-p-9 tw-rounded-md tw-w-full tw-text-dark-green tw-font-semibold"
            >
              <div
                v-for="(option, i) in question.options"
                :key="i"
                class="tw-mb-5 last:tw-mb-0"
              >
                {{ option }}
              </div>
            </div>
          </div>
          <div v-if="question.addOptionAllowed" class="tw-mb-5 tw-mx-2">
            <h4
              class="tw-text-left tw-text-light-grey tw-text-sm tw-mb-4 tw-ml-2"
            >
              Add at least two options.
            </h4>
            <div
              class="tw-mb-3"
              v-for="(option, i) in question.options"
              :key="i"
            >
              <div class="tw-flex tw-flex-row tw-items-center">
                <v-expansion-panels
                  v-if="question.text === 'What date(s) work best?'"
                  :ref="'datepickerPanel_' + i"
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <div
                        class="j-panel-header tw-flex tw-flex-row tw-items-center tw-justify-between"
                      >
                        <v-icon>mdi-calendar-blank</v-icon>
                        <div
                          class="tw-text-dark-green tw-text-base tw-font-semibold tw-mx-auto"
                        >
                          <span v-if="option.length">{{
                            getFormattedDateRange(option[0], option[1])
                          }}</span>
                          <span v-else>Select a date range</span>
                        </div>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-date-picker
                        class="tw-py-4"
                        v-model="question.options[i]"
                        range
                        no-title
                        color="secondary"
                        @change="closePanel(i)"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <div
                  v-else
                  class="tw-flex tw-flex-row tw-justify-between tw-items-center tw-w-full"
                >
                  <j-text-field
                    v-model="question.options[i]"
                    elevation="1"
                    light
                    background-color="#fafafa"
                    color="#203848"
                    class="tw-w-full"
                    hide-details
                    placeholder="Add Option"
                  />
                </div>
                <v-btn
                  class="tw-w-10 tw-h-10"
                  v-if="question.options.length > 2"
                  @click.stop="removeOption(question, i)"
                  icon
                >
                  <v-icon size="20" class="tw-text-charcoal">mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
            <div class="tw-text-left">
              <button
                class="tw-text-sm tw-text-light-grey tw-underline"
                @click.stop="addOption(question)"
              >
                Add Option
              </button>
            </div>
          </div>
          <div
            class="tw-mb-6 tw-flex tw-flex-row tw-justify-between tw-items-start"
            v-if="question.customQuestion"
          >
            <div class="tw-mx-2">
              <p class="tw-text-left tw-text-black tw-text-sm tw-mt-1 tw-mb-1">
                Allow Multiple selections
              </p>
              <p class="te-text left tw-text-light-grey tw-text-xs">
                Allow the crew to choose more than one
              </p>
            </div>

            <v-switch
              color="secondary"
              hide-details
              v-model="question.allowMultipleAnswers"
              inset
              class="tw-mt-0"
            />
          </div>
        </div>
        <div
          :key="i + '-separator'"
          class="tw-bg-separator-grey tw-w-full tw-h-2 tw-mb-10"
        ></div>
      </transition-group>
    </Draggable>
    <!-- <template v-for="(question, i) in surveyQuestionsSelected">
      <div :key="i" class="tw-px-7 tw-mb-10">
        <div
          class="tw-flex tw-flex-row tw-items-center tw-justify-between"
          :class="!question.addOptionAllowed ? 'tw-mb-5' : 'tw-mb-0'"
        >
          <input
            type="text"
            class="tw-text-left tw-text-dark-green tw-italic tw-text-base tw-font-semibold focus:tw-outline-none tw-w-full"
            v-model="question.text"
            @click="question.text = ''"
            v-if="question.customQuestion"
          />
          <h3
            class="tw-text-left tw-text-dark-green tw-text-base tw-font-semibold"
            v-else
          >
            {{ question.text }}
          </h3>
          <img
            src="@/assets/svgs/surveys/delete-question.svg"
            alt="Delete Question"
            class="tw-cursor-pointer"
            @click="deleteQuestion(i)"
          />
        </div>
        <div v-if="!question.addOptionAllowed" class="tw-mb-5">
          <span
            class="tw-block tw-text-left tw-text-light-grey tw-text-sm tw--mt-4 tw-mb-6"
            >Your crew will select from the options below.</span
          >
          <div
            class="tw-bg-lighter-grey tw-p-9 tw-rounded-md tw-w-full tw-text-dark-green tw-font-semibold"
          >
            <div
              v-for="(option, i) in question.options"
              :key="i"
              class="tw-mb-5 last:tw-mb-0"
            >
              {{ option }}
            </div>
          </div>
        </div>
        <div v-if="question.addOptionAllowed" class="tw-mb-5">
          <h4 class="tw-text-left tw-text-light-grey tw-text-sm tw-mb-4">
            Add at least two options.
          </h4>
          <div class="tw-mb-3" v-for="(option, i) in question.options" :key="i">
            <div class="tw-flex tw-flex-row tw-items-center">
              <v-expansion-panels
                v-if="question.text === 'What date(s) work best?'"
                :ref="'datepickerPanel_' + i"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div
                      class="j-panel-header tw-flex tw-flex-row tw-items-center tw-justify-between"
                    >
                      <v-icon>mdi-calendar-blank</v-icon>
                      <div
                        class="tw-text-dark-green tw-text-base tw-font-semibold tw-mx-auto"
                      >
                        <span v-if="option.length">{{
                          getFormattedDateRange(option[0], option[1])
                        }}</span>
                        <span v-else>Select a date range</span>
                      </div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-date-picker
                      class="tw-py-4"
                      v-model="question.options[i]"
                      range
                      no-title
                      color="secondary"
                      @change="closePanel(i)"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <div
                v-else
                class="tw-flex tw-flex-row tw-justify-between tw-items-center tw-w-full"
              >
                <j-text-field
                  v-model="question.options[i]"
                  elevation="1"
                  light
                  background-color="#fafafa"
                  color="#203848"
                  class="tw-w-full"
                  hide-details
                  placeholder="Add Option"
                />
              </div>
              <v-btn
                class="tw-w-10 tw-h-10"
                v-if="question.options.length > 2"
                @click.native="removeOption(question, i)"
                icon
              >
                <v-icon size="20" class="tw-text-charcoal">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="tw-text-left">
            <button
              class="tw-text-xs tw-text-light-grey tw-underline"
              @click="addOption(question)"
            >
              Add Option
            </button>
          </div>
        </div>
        <div
          class="tw-mb-6 tw-flex tw-flex-row tw-justify-between tw-items-start"
          v-if="question.customQuestion"
        >
          <div>
            <p class="tw-text-left tw-text-black tw-text-sm tw-mt-1 tw-mb-1">
              Allow Multiple selections
            </p>
            <p class="te-text left tw-text-light-grey tw-text-xs">
              Allow the crew to choose more than one
            </p>
          </div>

          <v-switch
            color="secondary"
            hide-details
            v-model="question.allowMultipleAnswers"
            inset
            class="tw-mt-0"
          />
        </div>
      </div>
      <div
        :key="i + '-separator'"
        class="tw-bg-separator-grey tw-w-full tw-h-2 tw-mb-10"
      ></div>
    </template> -->
    <div class="tw-px-7">
      <v-btn
        rounded
        color="secondary"
        x-large
        block
        class="tw-tracking-normal tw-mb-5"
        @click="activateQuestionsOverlay()"
        ><v-icon>mdi-plus</v-icon>Add a Question</v-btn
      >
      <v-btn
        @click.native="createSurvey"
        block
        rounded
        x-large
        :disabled="cantSubmit || surveyCreatePending"
        :loading="surveyCreatePending"
        color="primary"
        class="j-shadow-override tw-mb-5 tw-tracking-normal"
      >
        Create Survey
      </v-btn>
      <router-link
        class="tw-text-dark-green tw-border-b tw-border-solid tw-border-dark-green tw-font-bold tw-inline-block"
        :to="{ name: 'TripViewRoute', params: { id: trip.id } }"
      >
        Skip and Create Later
      </router-link>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";
import { SUGGESTED_QUESTIONS, OTHER_QUESTIONS } from "@/enums/questions.js";
import Draggable from "vuedraggable";
export default {
  name: "ConfirmSurveyForm",
  mixins: [FormattedDateRange],
  components: {
    Draggable
  },
  data() {
    return {
      dataLoaded: false,
      showQuestionsOverlay: false,
      surveyQuestionsSelected: [],
      surveyQuestionsSelectedTexts: [],
      questions: [...SUGGESTED_QUESTIONS, ...OTHER_QUESTIONS],
      questionObject: {
        id: null,
        slug: "",
        customQuestion: true,
        type: "MULTIPLE",
        text: "Type your question here",
        options: [""],
        addOptionAllowed: true,
        allowMultipleAnswers: false
      }
    };
  },
  watch: {
    surveyQuestionsSelectedTexts: {
      handler: function (val) {
        localStorage.setItem(
          "surveyQuestions",
          JSON.stringify(this.surveyQuestionsSelectedTexts)
        );

        this.adjustQuestionObjects(val);
      },
      deep: true
    },
    surveyQuestionsSelected: {
      handler: function (val) {
        val.forEach((q) => {
          if (q.slug === "dates") {
            q.options.forEach((o) => {
              if (o[0] && o[1]) {
                let temp = [...o];
                if (new Date(o[0]) > new Date(o[1])) {
                  o[0] = temp[1];
                  o[1] = temp[0];
                }
              }
            });
          }
        });
        localStorage.setItem(
          "surveyQuestionsObjects",
          JSON.stringify(this.surveyQuestionsSelected)
        );
      },
      deep: true
    }
  },
  computed: {
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    },
    surveyQuestions() {
      let savedQuestions = [];
      if (localStorage.getItem("surveyQuestions"))
        savedQuestions = JSON.parse(localStorage.getItem("surveyQuestions"));

      return this.$store.state.meta.surveyQuestions.length > 0
        ? this.$store.state.meta.surveyQuestions
        : savedQuestions;
    },
    surveyQuestionsObjects() {
      let savedQuestionsObjects = [];
      if (localStorage.getItem("surveyQuestionsObjects"))
        savedQuestionsObjects = JSON.parse(
          localStorage.getItem("surveyQuestionsObjects")
        );

      return savedQuestionsObjects;
    },
    surveyCreatePending() {
      return this.$store.state["survey-builder"].isCreatePending;
    },
    cantSubmit() {
      const hasInsufficientOptions = this.surveyQuestionsSelected.some(
        (question) => {
          return (
            question.options.length < 2 ||
            question.options.some((option) => option.length === 0)
          );
        }
      );

      return (
        this.surveyQuestionsSelected.length === 0 || hasInsufficientOptions
      );
    }
  },
  created() {
    this.surveyQuestions.forEach((question) => {
      if (question.text === "What date(s) work best?") {
        this.$set(question, "isOpen", false);
        this.$set(question, "dates", null);
      }
      if (
        question.addOptionAllowed &&
        !question.options.length &&
        question?.dates === undefined
      ) {
        // Ensuring at least one option is available on page load, except for questions with a datepicker
        question.options.push("");
      }
    });
  },
  methods: {
    adjustQuestionObjects(texts) {
      this.surveyQuestionsSelected.forEach((obj, index) => {
        if (!texts.includes(obj.text) && !obj.customQuestion) {
          this.surveyQuestionsSelected.splice(index, 1);
        }
      });

      const missingQuestions = texts.filter(
        (text) => !this.surveyQuestionsSelected.some((obj) => obj.text === text)
      );

      this.questions.forEach((q) => {
        if (missingQuestions.includes(q.text)) {
          this.surveyQuestionsSelected.push(q);
        }
      });
    },
    cloneObject(obj) {
      if (obj === null || typeof obj !== "object" || "isActiveClone" in obj) {
        return obj;
      }
      let temp = null;
      if (obj instanceof Date) {
        temp = new Date(obj);
      } else {
        temp = obj.constructor();
      }
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          obj["isActiveClone"] = null;
          temp[key] = this.cloneObject(obj[key]);
          delete obj["isActiveClone"];
        }
      }
      return temp;
    },
    activateQuestionsOverlay() {
      this.showQuestionsOverlay = true;
      document.querySelector("body").classList.add("fixed");
    },
    closeQuestionsOverlay(custom = null) {
      if (custom) {
        let question = this.cloneObject(this.questionObject);
        this.surveyQuestionsSelected.unshift(question);
      }
      this.showQuestionsOverlay = false;
      document.querySelector("body").classList.remove("fixed");
    },
    questionInArray(question) {
      return this.surveyQuestionsSelectedTexts.some((q) => q === question.text);
    },
    closePanel(i) {
      const panelRef = this.$refs["datepickerPanel_" + i][0];
      if (panelRef) panelRef.internalValue = undefined;
    },
    addOption(question) {
      if (question.text === "What date(s) work best?")
        question.options.push([]);
      else question.options.push("");
    },
    removeOption(question, i) {
      question.options.splice(i, 1);
    },
    deleteQuestion(q, i) {
      if (q.customQuestion) {
        this.surveyQuestionsSelected.splice(i, 1);
      } else {
        this.surveyQuestionsSelected.splice(i, 1);
        let index = this.surveyQuestionsSelectedTexts.indexOf(q.text);
        if (index !== -1) {
          this.surveyQuestionsSelectedTexts.splice(index, 1);
        }
      }
    },
    convertDatesToStrings(surveyQuestions) {
      for (const question of surveyQuestions) {
        if (question.text === "What date(s) work best?") {
          const options = question.options;
          options.forEach((option, index) => {
            if (Array.isArray(option)) {
              this.$set(question.options, index, option.join(","));
            }
          });
        }
      }
    },
    async createSurvey() {
      this.convertDatesToStrings(this.surveyQuestionsSelected);

      //set weight property by index
      let index = 0;
      this.surveyQuestionsSelected.forEach((q) => {
        q.weight = index;
        index++;
      });

      const survey = {
        tripId: this.$store.state.meta.currentTrip.id,
        isAnonymous: false,
        title: this.$store.state.meta.currentTrip.title,
        questions: this.surveyQuestionsSelected
      };

      this.$store
        .dispatch("survey-builder/create", {
          ...survey,
          title: this.trip.title,
          tripId: this.trip.id
        })
        .then((res) => {
          const now = DateTime.now();
          const start = DateTime.fromISO(this.$store.state.auth.user.createdAt);
          const diff = start.diff(now, "days").toObject();
          const count = Math.abs(Math.round(diff.days));
          let newUser = false;
          let hasDates = false;
          if (count <= 7) {
            newUser = true;
          }

          if (this.trip.startDate && this.trip.endDate) hasDates = true;

          window.dataLayer.push({
            event: "add_trip_survey",
            uuid: this.$store.state.auth.user.uuid,
            signup_date: DateTime.fromISO(
              this.$store.state.auth.user.createdAt
            ).toISO(),
            new_user: newUser,
            verified_user: this.$store.state.auth.user.isEmailVerified,
            sign_up_trip_id: this.$store.state.auth.user.signupTripId,
            trip_id: this.trip.id,
            owner_id: this.trip.ownerId,
            has_dates: hasDates,
            has_destination:
              this.trip.destinations && this.trip.destinations.length > 0,
            survey_id: res.surveyId ? res.surveyId : null,
            page_location: sessionStorage.getItem("clicked_survey_creation"),
            invitation_attached: this.trip.trip_invitation ? true : false,
            response_deadline:
              this.trip.trip_invitation &&
              this.trip.trip_invitation.rsvpDeadline
                ? this.trip.trip_invitation.rsvpDeadline
                : null,
            number_of_survey_questions: res.questions.length
          });

          this.$router.push({
            name: "SurveyCreatedConfirmation",
            params: { id: res.tripId }
          });
        });
    }
  },
  beforeMount() {
    //remove meta data being stored into SUGGESTED_QUESTIONS and OTHER_QUESTIONS
    this.questions.forEach((q) => {
      if (
        q.text === "What date(s) work best?" ||
        q.text === "Where would you like to go?"
      )
        q.options = [];
    });
  },
  async mounted() {
    this.$store.commit("meta/setMainContainerPadding", {
      defaultContainerPaddingDisabled: true
    });

    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Trip Survey",
      bgColor: "#203848",
      fontColor: "#E6FFA1",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      showBackButton: false,
      tripHubButton: true,
      tripHubButtonId: this.$route.params.id
    });

    await this.$store.dispatch("trip/get", [
      this.$route.params.id,
      {
        query: {
          include: "destination,users,survey,trip_invitation"
        }
      }
    ]);

    this.surveyQuestionsSelectedTexts = this.surveyQuestions || [];
    this.surveyQuestionsSelected = this.surveyQuestionsObjects || [];

    this.dataLoaded = true;
  },
  updated() {
    this.$store.commit("meta/setMainContainerPadding", {
      defaultContainerPaddingDisabled: true
    });
  },
  destroyed() {
    document.querySelector("body").classList.remove("fixed");
    this.$store.commit("meta/setMainContainerPadding", {
      defaultContainerPaddingDisabled: false
    });

    localStorage.removeItem("surveyQuestions");
    localStorage.removeItem("surveyQuestionsObjects");
  }
};
</script>

<style lang="scss">
.j-text-field.v-text-field.v-input--is-disabled input {
  @apply tw-text-dark-green;
}

.j-link {
  @apply tw-block tw-mx-auto  tw-text-base tw-font-semibold tw-leading-6 tw-underline tw-cursor-pointer;
}

button.v-btn.v-btn--active {
  background-color: #203848 !important;
  color: #ffffff !important;

  .v-icon:before {
    color: #ffffff;
  }
}
</style>
